import React from "react";
import theme from "theme";
import { Theme, Text, Icon, LinkBox, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import { BsArrowLeftShort } from "react-icons/bs";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"404"} />
		<Helmet>
			<title>
				Everweave - Play D&D online with AI DM
			</title>
			<meta name={"description"} content={"Web site created using quarkly.io"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66777bc18d4a0c002030d07b/images/favicon.png?v=2024-07-09T17:04:35.953Z"} type={"image/x-icon"} />
		</Helmet>
		<Section
			padding="150px 0 150px 0"
			min-height="100vh"
			background="linear-gradient(180deg,--color-dark 0%,transparent 86.7%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/66777bc18d4a0c002030d07b/images/404.png?v=2024-06-23T01:38:11.196Z) 0% 0% /cover no-repeat scroll padding-box"
			lg-padding="90px 0 90px 0"
			quarkly-title="404-2"
		>
			<Override slot="SectionContent" max-width="1220px" justify-content="flex-start" />
			<Box align-items="center" display="flex" justify-content="center" flex-direction="column">
				<Text margin="0px 0px 0px 0px" font="--headline3" color="--dark">
					404
				</Text>
				<Text
					color="rgba(247, 251, 255, 0)"
					margin="8px 0px 16px 0px"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					font="--headline1"
					letter-spacing="-0.025em"
					lg-margin="0px 0px 16px 0px"
					border-color="--color-light"
					white-space="normal"
					background="--color-light linear-gradient(0deg,rgba(0,0,0,0) 0%,rgba(0,0,0,0) 100%) 0% 0% scroll"
					background-clip="text"
				>
					Page not found
				</Text>
				<Text
					lg-width="80%"
					font="--lead"
					color="--light"
					margin="0px 0px 36px 0px"
					text-align="center"
					lg-margin="0px 0px 24px 0px"
				>
					Sorry, we couldn’t find the page you’re looking for.
				</Text>
				<LinkBox
					flex-direction="row"
					padding="12px 8px 12px 8px"
					transition="--opacityOut"
					hover-opacity="0.7"
					lg-margin="0px 0px 20px 0px"
				>
					<Icon
						category="bs"
						icon={BsArrowLeftShort}
						size="24px"
						margin="4px 4px 0px 0px"
						color="--light"
					/>
					<Text margin="0px 0px 0px 0px" font="--lead" color="--light">
						Back to home
					</Text>
				</LinkBox>
			</Box>
		</Section>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"66777bc18d4a0c002030d079"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});